import React, { Component } from "react";
import MaterialTableComponent from "../../widgets/materialTable";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import adminService from "../../../Services/adminService";
import Swal from "sweetalert2";
import { Button, TextField } from "@material-ui/core";
import employeeServices from "../../../Services/employeeService";
import { Autocomplete } from "@material-ui/lab";
import countryList from "../../../assets/data/countriesList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <>{children} </>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

class AgentsManager extends Component {
  state = {
    tab: 0,
    allowedDelete: false,
    agencyName: "",
    agencyId: "",
    agentsList: null,
    groupAgentsList: null,
    visasAffiliated: null,
    columns: [
      { title: "שם פרטי", field: "agentFirstName" },
      { title: "שם משפחה", field: "agentLastName" },
      {
        title: "סטטוס",
        field: "agentStatus",
        lookup: {
          active: "פעיל",
          suspended: "מושהה"
        }
      },
      { title: "מספר סוכן", field: "agentMobile" },
      { title: "מייל סוכן", field: "agentEmail" }
    ],
    fromAffiliateLinkGeneration: "",
    toAffiliateLinkGeneration: "",
    affiliateFromToLink: ""
  };

  constructor(props) {
    super();
    this.redirectToAgentsGroups = (agentData, e) => {
      if (e.ctrlKey) window.open("/admin/agent-groups/" + agentData._id, "_blank");
      else this.props.history.push({ pathname: "/admin/agent-groups/" + agentData._id, state: agentData });
    };
    this.state.agencyName = props.match.params.agencyName;
    this.state.agencyId = props.match.params.agencyId;
    if (localStorage.getItem("role") === "admin" || localStorage.getItem("role") === "processor") this.state.allowedDelete = true;
  }

  componentDidMount() {
    const agencyId = this.props.match.params.agencyId;

    employeeServices.getAgents(agencyId).then((res) => {
      if (res["success"]) {
        const agentsList = res.agentsList.filter((agent) => !agent.agentType || agent.agentType.includes("reg"));
        const groupAgentsList = res.agentsList.filter((agent) => agent.agentType && agent.agentType.includes("group"));
        this.setState({ agentsList, groupAgentsList });
      }
    });
  }

  render() {
    return (
      <div className="container pb-70 rtl-layout right">
        <h2 className="yellow-color">
          שם סוכנות: <span className="black-color"> {this.state.agencyName}</span>
        </h2>

        <hr />
        <div>
          <h4 className="primary-color">
            <b>יצירת דוח</b>
          </h4>
          <div className="inline-block">
            תאריך תחילה
            <input onChange={this.reportDateChange} type="date" id="startDate" />
          </div>
          <div className="inline-block mr-30">
            תאריך סוף
            <input onChange={this.reportDateChange} type="date" id="endDate" />
          </div>
          <div className="inline-block mr-30">
            <Button onClick={this.downloadVouchers} color="primary" variant="contained" size="small">
              הורדת שוברים
            </Button>
          </div>
          <div className="inline-block mr-30">
            <Button onClick={this.createReport} color="secondary" variant="contained" size="small">
              דוח
            </Button>
          </div>
        </div>

        <div className="row mt-50">
          <div className="user-table-con col-sm-12">
            {!this.state.agentsList ? (
              <h4 className="primary-color">טוען מידע...</h4>
            ) : (
              <div className="relative">
                <div>
                  <Tabs
                    value={this.state.tab}
                    onChange={(_e, value) => {
                      this.setState({ tab: value });
                      if (value === 2) {
                        employeeServices.getAffiliatedVisaList(this.state.agencyId).then((res) => {
                          const visasAffiliated = res.visasAffiliated.map((x) => {
                            return {
                              ...x,
                              fullName: x.firstName + " " + x.lastName,
                              dateCreated: new Date(x.dateCreated).toLocaleDateString()
                            };
                          });
                          if (res["success"]) this.setState({ visasAffiliated });
                        });
                      }
                    }}
                  >
                    <Tab label="Agents" />
                    <Tab label="Group Agents" />
                    <Tab label="Affiliates" />
                  </Tabs>
                </div>
                {this.state.agentsList ? (
                  <>
                    <TabPanel value={this.state.tab} index={0}>
                      <MaterialTableComponent
                        agentType="reg"
                        options={{ pageSizeOptions: [5, 10, 20, 100] }}
                        data={this.state.agentsList}
                        columns={this.state.columns}
                        onDataChanged={this.handleDataChange}
                        tableTitle="טבלת סוכנים"
                        allowedDelete={this.state.allowedDelete}
                        performResendEmailConf={this.performResendEmailConf}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.tab} index={1}>
                      <MaterialTableComponent
                        agentType="group"
                        redirectToAgentsGroups={this.redirectToAgentsGroups}
                        options={{ pageSizeOptions: [5, 10, 20, 100] }}
                        data={this.state.groupAgentsList}
                        columns={this.state.columns}
                        onDataChanged={this.handleDataChange}
                        tableTitle="טבלת סוכני קבוצות"
                        allowedDelete={this.state.allowedDelete}
                        performResendEmailConf={this.performResendEmailConf}
                      />
                    </TabPanel>
                    <TabPanel value={this.state.tab} index={2}>
                      <div className="visas-button-con mt-20 mb-20">
                        <Autocomplete
                          options={countryList}
                          style={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="מוצא" variant="outlined" />}
                          onChange={(event, newInputValue) => {
                            this.setState({
                              fromAffiliateLinkGeneration: newInputValue
                            });
                          }}
                        />

                        <Autocomplete
                          // className="auto0"
                          options={countryList}
                          style={{ width: 300 }}
                          renderInput={(params) => <TextField {...params} label="יעד" variant="outlined" />}
                          onChange={(event, newInputValue) => {
                            this.setState({
                              toAffiliateLinkGeneration: newInputValue
                            });
                          }}
                        />

                        <button
                          onClick={() => {
                            if (!this.state.fromAffiliateLinkGeneration || !this.state.toAffiliateLinkGeneration) {
                              this.setState({ affiliateFromToLink: "" });
                              return Swal.fire("", "יש לבחור מוצא ויעד", "warning");
                            }

                            const to = this.state.toAffiliateLinkGeneration.toLowerCase();
                            const from = this.state.fromAffiliateLinkGeneration.toLowerCase();

                            this.setState({
                              affiliateFromToLink: `https://myvisae.com/visa-type/${from}/${to}/?affiliateId=${this.state.agencyId}`
                            });
                          }}
                          className="btn btn-primary"
                        >
                          יצר לינק
                        </button>
                      </div>

                      {this.state.affiliateFromToLink && <div className="mt-20 mb-20">{this.state.affiliateFromToLink}</div>}
                      <MaterialTableComponent
                        options={{ pageSizeOptions: [5, 10, 20, 100] }}
                        data={this.state.visasAffiliated || []}
                        columns={[
                          { title: "שם מלא", field: "fullName" }, //, editable: "onAdd"
                          { title: "מספר דרכון", field: "passportNum", editable: "never" },
                          { title: 'דוא"ל', field: "email", editable: "never" },

                          {
                            title: "סטטוס",
                            field: "status",
                            lookup: {
                              AGENT_PAID_AND_FILLS: "ממתין לטיפולך",
                              AGENT_PAID_CLIENT_FILLS: "ממתין לטיפול הלקוח שלך",
                              WAITING_PROCESSOR_PAID: "אנו מטפלים בבקשה",
                              WAITING_PROCESSOR_NOT_PAID: " אנו מטפלים בבקשה לא שולם",
                              WAITING_IN_OFFICE: "נמצא במשרד",
                              GOT_INTO_EMBASSY: "נכנס לקונסוליה",
                              WAITING_FOR_DOCUMENTS: "ממתינים למסמכים נוספים",
                              ACCES_GRANTED: "קיבל אשרה",
                              WITHOUT_TREATMENT: "ללא טיפול",
                              DENIED: "סירוב",
                              REFUND: "החזר כספית"
                            }
                          },
                          { title: "מוצא", field: "from" },
                          { title: "יעד", field: "to" },
                          { title: "נוצר ב", field: "dateCreated" }
                        ]}
                        tableTitle="טבלת ויזות מאפילייטים"
                      />
                    </TabPanel>
                  </>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  reportDateChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  performResendEmailConf = (currentAgent) => {
    adminService.performResendEmailConf(currentAgent.agentEmail).then((res) => {
      if (res.success) {
        Swal.fire("", "מייל עם איפוס סיסמה נשלח בהצלחה", "success");
      }
    });
  };

  createReport = () => {
    var state = this.state;
    if (!state.endDate || !state.startDate) {
      return Swal.fire("", "יש להכניס תאריך התחלה ותאריך סיום", "warning");
    }
    if (state.startDate < "2005-01-01") {
      return Swal.fire("", "יש להכניס תאריך התחלה החל מ- 01-01-2005", "warning");
    }
    var data = { agencyId: state.agencyId, startDate: state.startDate, endDate: state.endDate };
    adminService.getAgencyReport(data).then((res) => {
      if (res["success"]) {
        const reportName = "Report " + state.startDate + " ~ " + state.endDate;
        if (!res.reportFormatArr || !res.reportFormatArr.length) {
          return Swal.fire("לא קיים מידע", "לא קיים מידע עבור הסוכנות המבוקשת בזמן שנבחר", "info");
        }

        adminService.exportExcelClientSide(res["reportFormatArr"], null, false, reportName);
      }
    });
  };

  downloadVouchers = () => {
    var state = this.state;
    if (!state.endDate || !state.startDate) {
      Swal.fire("", "יש להכניס תאריך התחלה ותאריך סיום", "warning");
      return;
    }
    var data = { agencyId: state.agencyId, startDate: state.startDate, endDate: state.endDate };
    adminService.getAgencyVouchers(data).then((res) => {});
  };

  handleDataChange = (dataToModify, action, agentType) => {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: "האם אתה בטוח?",
        text: "אתה הולך לעשות את הפעולה הבאה על המשתמש: " + action,
        icon: "warning",
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          adminService.handleAgentChanges(dataToModify, action, this.state.agencyId, agentType).then((res) => {
            if (res["success"]) {
              if (res["newId"]) resolve(res["newId"]);
              else resolve();
              Swal.fire(`${action} - בוצע`, "", "success");
            } else {
              if (res["isExistingUser"]) {
                Swal.fire(`${action} - לא בוצע`, "קיים כבר משתמש", "warning");
              }
              reject();
            }
          });
        } else {
          reject();
        }
      });
    });
  };
}

export default AgentsManager;
