import config from "../config";
import axios from "axios";

const visaService = {
  getAllFromToOpts: function () {
    return new Promise((resolve) => {
      axios.get(config.allFromToOptsEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getVisaTypes: (from, to) => {
    return new Promise((resolve) => {
      var buildEndpoint = `${config.getVisaTypesEndpoint}?from=${from}&to=${to}`;
      axios.get(buildEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getVisaFields: (from, to, visaType) => {
    return new Promise((resolve) => {
      var buildEndpoint;
      if (from && to && visaType) {
        buildEndpoint = `${config.getVisaFieldsEndpoint}?from=${from}&to=${to}&visaType=${visaType}`;
      } else {
        buildEndpoint = config.getVisaFieldsEndpoint;
      }
      axios.get(buildEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  createNewVisa: (fields) => {
    return new Promise((resolve) => {
      axios.post(config.createNewVisaEndpoint, fields).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  createNewGroupVisa: (fields) => {
    return new Promise((resolve) => {
      axios.post(config.createNewGroupVisaEndpoint, fields).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  addAgentVisaCliName: (visaId, passportNum, firstName, lastName) => {
    return new Promise((resolve) => {
      axios.post(config.updateVisaNameEndpoint, { visaId, passportNum, firstName, lastName }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  updateAgentFirstData: (visaId, passportNum, firstName, lastName) => {
    return new Promise((resolve) => {
      axios.post(config.updateAgentFirstDataEndpoint, { visaId, passportNum, firstName, lastName }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },
  updateVisaFields: (fields) => {
    return new Promise((resolve) => {
      axios.post(config.updateVisaFieldsEndpoint, fields).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  retrieveVisaDetails: (visaToFind) => {
    return new Promise((resolve) => {
      axios.post(config.retrieveVisaDetailsEndpoint, visaToFind).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  attachFileToVisa: (visaId, passportNum, fieldId, selectedFile) => {
    return new Promise((resolve) => {
      var fd = new FormData();
      fd.append("file", selectedFile[fieldId], selectedFile.name);

      var buildLink = `${config.attachFileToVisaEndpoint}?visaId=${visaId}&passportNum=${passportNum}&fieldId=${fieldId}`;
      axios.post(buildLink, fd).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  finishVisaProcess: (visaId, affiliateId, passportNum, whoFills, lastField) => {
    return new Promise((resolve) => {
      let endpoint = config.finishUnpaidVisaEndpoint;
      if (whoFills) {
        if (whoFills === "clientfills") endpoint = config.finishVisaClientFillsEndpoint;
        else endpoint = config.finishVisaProcessAgencyRelatedEndpoint;
      }
      axios.post(endpoint, { visaId, affiliateId, passportNum, lastField }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  saveNewContactUsReq: (fields) => {
    return new Promise((resolve) => {
      axios.post(config.contactUsMsgEndpoint, fields).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  upperCaseCountry(countryName) {
    var split = countryName.split(" ");
    for (let i = 0; i < split.length; i++) {
      split[i] = split[i].charAt(0).toUpperCase() + split[i].slice(1);
    }
    var join = split.join(" ");
    return join;
  },

  newDubaiLead: (fullName, email, mobile) => {
    return new Promise((resolve) => {
      axios.post(config.newDubaiLeadEndpoint, { fullName, email, mobile }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  checkIsVisaPaid: (visaId) => {
    return new Promise((resolve) => {
      axios.post(config.checkIsVisaPaidEndpoint, { visaId }).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getPaymentIframeURL: (transactionInitData) => {
    return new Promise((resolve) => {
      axios.post(config.getPaymentIframeEndpoint, transactionInitData).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  }
};

export default visaService;
