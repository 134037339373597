import React, { Component } from "react";
import Swal from "sweetalert2";
import "../../assets/css/oneByOneQuestionnaire.css";
import { connect } from "react-redux";
import TopQuestionnaireNav from "./topQuestionnaireNav";
import BottomSlider from "./bottomSlider";
import QuestionDisplayer from "./questionDisplayer";
import visaService from "../../Services/visaService";
import validationService from "../../Services/validation/validationService";
import config from "../../config";
import moment from "moment";

class OneByOneManager extends Component {
  state = {
    from: "",
    to: "",
    visaType: "",
    pageTxt: {},
    visaId: "",
    isLoading: false,
    delayerOn: false,
    questionnaireType: "",
    questionsListForDisplayment: [],
    questionsListForDisplaymentCopy: [],
    fieldVals: {
      // TEST FIELDS for canada
      // visitDate: moment().add(2, "M").format("YYYY-MM-DD"),
      // workPhone: "0501234567",
      // workAddress: "כתובת מקום העבודה",
      // workName: "שם מקום העבודה",
      // birthCity: "תל אביב",
      // fullAddress: "רחוב הרצל 1, תל אביב",
      // profession: "מהנדס",
      // deportedQuestion: "no",
      // phoneNumber: "0501234567",
      // passportNum: "123456789",
      // email: "asdasdas@gmail.com",
      // lastName: "TEST",
      // firstName: "TEST"
    },
    currentValidationErr: "",
    questionIndexer: 0
  };

  componentDidMount() {
    if (this.props.isLoggedIn && !this.props.visaToEdit) {
      // agents are not allowed in here without pre-made visa
      this.props.history.push("/agents/profile");
      return;
    }

    const queryParams = new URLSearchParams(this.props.location.search);
    const affiliateId = queryParams.get("affiliateId");

    const { from, to, visaType } = this.props.match.params;

    visaService.getVisaFields(from, to, visaType).then((res) => {
      if (res["success"]) {
        var lang = "he";
        var fields = res["neededFields"].map((item) => {
          if (item["title-" + lang]) item.title = item["title-" + lang];
          if (item["elaboration-" + lang]) item.elaboration = item["elaboration-" + lang];
          if (item["label-" + lang]) item.label = item["label-" + lang];
          if (item["options-" + lang]) item.options = item["options-" + lang];

          return item;
        });

        const newState = {
          from,
          to,
          visaType,
          questionsListForDisplayment: fields,
          questionsListForDisplaymentCopy: [...fields],
          visaPrice: res.visaType.visaPrice,
          visaService: res.visaType.visaService
        };
        if (affiliateId) newState.affiliateId = affiliateId;

        if (this.props.visaToEdit) {
          newState.visaId = this.props.visaToEdit._id;
          newState.passportNum = this.props.visaToEdit.passportNum;

          var fieldVals = this.convertDateFieldVals();
          if (this.props.visaToEdit.belongToAgencyId) {
            // Agent visa
            if (this.props.visaToEdit.whoFills === "clientfills") {
              // remove passportNum + email questions
              fields = fields.filter((item) => item.key !== "passportNum" && item.key !== "email");
              newState.questionsListForDisplaymentCopy = newState.questionsListForDisplaymentCopy.filter(
                (item) => item.key !== "passportNum" && item.key !== "email"
              );
            } else {
              fields = fields.filter((item) => item.key !== "email");
              newState.questionsListForDisplaymentCopy = newState.questionsListForDisplaymentCopy.filter((item) => item.key !== "email");
            }
            newState.whoFills = this.props.visaToEdit.whoFills;
            newState.belongToAgencyId = this.props.visaToEdit.belongToAgencyId;
          }

          // return when we want credit card
          else if (config.isWebsiteB2C) {
            // Adding payment question
            newState.questionsListForDisplayment.push({ type: "termsForPayment" });
            newState.questionsListForDisplaymentCopy.push({ type: "termsForPayment" });
            newState.questionsListForDisplayment.push({ type: "payment" });
            newState.questionsListForDisplaymentCopy.push({ type: "payment" });
          }
          newState.questionsListForDisplayment = this.removeDependedFieldsOnInit(fields, fieldVals);
          var questionIndexer = this.getQuestionIndexer(newState.questionsListForDisplayment, fieldVals);
          newState.questionIndexer = questionIndexer;
          newState.fieldVals = fieldVals;
        } else if (config.isWebsiteB2C) {
          // Adding payment question
          newState.questionsListForDisplayment.push({ type: "termsForPayment" });
          newState.questionsListForDisplaymentCopy.push({ type: "termsForPayment" });
          newState.questionsListForDisplayment.push({ type: "payment" });
          newState.questionsListForDisplaymentCopy.push({ type: "payment" });
        }

        this.setState(newState);
      }
    });
  }

  render() {
    var curQuestion = this.state.questionsListForDisplayment[0] ? this.state.questionsListForDisplayment[this.state.questionIndexer] : null;
    return (
      <div className={`full-screen-questions-wrapper`} onKeyPress={this.handleKeyPress}>
        <TopQuestionnaireNav history={this.props.history} />
        {curQuestion ? (
          <div className="questions-con relative">
            <div className="question-placement">
              {this.state.visaId ? (
                <div className="visa-id-con">
                  <div className="visa-id-text lightgray-brand">(שמור מזהה זה על מנת לחזור להזמנה בכל שלב)</div>
                  <h5 className="center primary-color">{this.state.visaId}</h5>
                </div>
              ) : null}
              {!this.state.delayerOn ? (
                <QuestionDisplayer
                  val={this.state.fieldVals[curQuestion.key]}
                  confirmPassVal={curQuestion.confirmPass ? this.state.fieldVals[curQuestion.confirmPass] : ""}
                  validationErr={this.state.currentValidationErr}
                  question={curQuestion}
                  handleFieldChange={this.handleFieldChange}
                  onContinue={this.onContinue}
                  handleCheckboxChange={this.handleCheckboxChange}
                  notifyFinishFlow={this.notifyFinishFlow}
                  visaPrice={this.state.visaPrice}
                  visaService={this.state.visaService}
                  visaId={this.state.visaId}
                  passportNum={this.state.passportNum}
                  to={this.state.to}
                  visaType={this.state.visaType}
                  fieldVals={this.state.fieldVals}
                  affiliateId={this.state.affiliateId}
                />
              ) : null}
            </div>

            {this.isBackAllowed() ? (
              <div className="back-question-con">
                <i onClick={this.initPrevQuestion} className="material-icons">
                  keyboard_arrow_left
                </i>
              </div>
            ) : null}
          </div>
        ) : null}
        {this.state.isLoading ? <div>Loading</div> : null}
        <div className="mb-60"></div>

        <BottomSlider stepsAmount={this.state.questionsListForDisplayment.length} stepsAmountFinished={this.state.questionIndexer + 1} />
      </div>
    );
  }

  handleFieldChange = (e, key) => {
    var fieldVals = { ...this.state.fieldVals };
    fieldVals[key || e.target.id] = e.target.value;
    this.setState({
      fieldVals,
      currentValidationErr: ""
    });
  };

  onContinue = async (question, val, confirmPassVal) => {
    // if (this.functionIsRunning) return;
    // this.functionIsRunning = true;
    // value will represent file if that is the case

    // skipOnContinue means no validation, just continue(for example a msg to client).
    if (!question || question.skipOnContinue) {
      return this.setState({ questionIndexer: this.state.questionIndexer + 1, delayerOn: false }, () => {
        this.functionIsRunning = false;
      });
    }

    this.setState({ currentValidationErr: "" });

    validationService.validateOneField(question, val).then(
      (validatedData) => {
        /// at this point i can assume that the field is validated ///
        this.checkForActionsToDo(question.key, validatedData, question).then(
          (listOfDeletedKeys) => {
            this.setState({ delayerOn: true });

            var updatesForDB = {};
            var fieldVals = { ...this.state.fieldVals };
            fieldVals[question.key] = validatedData;
            updatesForDB[question.key] = validatedData;

            if (listOfDeletedKeys && listOfDeletedKeys[0]) {
              for (const key of listOfDeletedKeys) {
                fieldVals[key] = "";
                updatesForDB[key] = "";
              }
            }
            this.setState({ fieldVals });

            // if we got here that means the field is validated but we dont want to save it yet
            // (for example the min required for a new visa -> firstname,lastname,email and passport)
            if (question.dontSave || question.type === "uploadFile") {
              return this.setState({ questionIndexer: this.state.questionIndexer + 1, delayerOn: false }, () => {
                this.functionIsRunning = false;
              });
            }

            this.setState({ questionIndexer: this.state.questionIndexer + 1, delayerOn: false }, () => {
              this.functionIsRunning = false;
            });

            updatesForDB["visaId"] = this.state.visaId;
            updatesForDB["passportNum"] = this.state.passportNum;

            visaService.updateVisaFields(updatesForDB).then((res) => {});
          },
          (problemToContinue) => {
            this.functionIsRunning = false;
          }
        );
      },
      (validationErr) => {
        this.functionIsRunning = false;
        this.setState({ currentValidationErr: validationErr.message });
      }
    );
  };

  initPrevQuestion = () => {
    if (this.state.questionIndexer === 0) this.props.history.push(`/visa-type/${this.state.from}/${this.state.to}`);
    this.setState({
      questionIndexer: this.state.questionIndexer - 1,
      currentValidationErr: ""
    });
  };

  isBackAllowed = () => {
    if (this.state.questionsListForDisplayment[this.state.questionIndexer].notAllowedBack) return false;
    return true;
  };

  checkForActionsToDo(lastId, lastVal, question) {
    return new Promise((resolve, reject) => {
      this.proccessOtherDepended(question, lastVal, (listOfDeletedKeys) => {
        this.canCreateNewVisa(lastId, () => {
          this.isAgentVisaActions(lastId, () => {
            this.fileUpload(lastId, lastVal, question, () => {
              this.isVisaFinished(question, lastId, lastVal, () => {
                return resolve(listOfDeletedKeys);
              });
            });
          });
        });
      });
    });
  }

  isVisaFinished = (question, lastId, lastVal, callback) => {
    if (this.state.questionIndexer === this.state.questionsListForDisplayment.length - 1 /* && this.state.whoFills === "agentfills"*/) {
      var state = this.state;

      const lastField = question.type !== "uploadFile" ? { lastId, lastVal } : null;
      visaService.finishVisaProcess(state.visaId, this.state.affiliateId, state.passportNum, this.state.whoFills, lastField).then((res) => {
        if (res["success"]) {
          this.props.history.push(
            `/finished-visa/${state.visaId}/${state.passportNum}/${state.fieldVals.firstName} ${state.fieldVals.lastName}/${state.from}/${state.to}`
          );
        }
      });
    } else {
      return callback();
    }
  };

  // Payment was successfully made.
  notifyFinishFlow = () => {
    // TODO - validate with the server before moving to 'finished visa' page.

    const state = this.state;

    this.props.history.push(
      `/finished-visa/${state.visaId}/${state.passportNum}/${state.fieldVals.firstName} ${state.fieldVals.firstName}/${state.from}/${state.to}`
    );
  };

  fileUpload = (lastId, lastVal, question, callback) => {
    if (question.type !== "uploadFile") return callback();

    const getExtName = lastVal[lastId].name.substring(lastVal[lastId].name.lastIndexOf("."));

    if (!config.allowedFilesFormat.includes(getExtName)) {
      Swal.fire(
        "פורמט קובץ לא תקין",
        "ניתן להעלות רק אחד מסוגי הקבצים הבאים" + config.allowedFilesFormat.map((x) => x.replace(".", "") + " ").toString(),
        "info"
      );
      return;
    }

    visaService.attachFileToVisa(this.state.visaId, this.state.fieldVals.passportNum, lastId, lastVal).then((res) => {
      return callback();
    });
  };

  canCreateNewVisa = (lastId, callback) => {
    if (lastId === "passportNum" && !this.state.belongToAgencyId) {
      // we can create a new visa
      var state = this.state;
      visaService.createNewVisa({ ...state.fieldVals, from: state.from, to: state.to, visaType: state.visaType }).then((res) => {
        if (res["success"]) {
          this.setState({ visaId: res["visaId"], passportNum: this.state.fieldVals["passportNum"] });
          return callback();
        }
        Swal.fire("Problem occured", "Please try again later", "warning").then(() => {
          // TODO -- check this
          this.props.history.push("/");
        });
      });
    } else {
      return callback();
    }
  };

  isAgentVisaActions = (lastId, callback) => {
    var state = this.state;
    if (state.belongToAgencyId) {
      if (lastId === "lastName" && state.whoFills === "clientfills") {
        // cli fills
        visaService
          .addAgentVisaCliName(state.visaId, state.passportNum, state.fieldVals.firstName, state.fieldVals.lastName)
          .then((res) => {
            if (res["success"]) {
              callback();
            }
          });
      } else if (lastId === "passportNum" && state.whoFills === "agentfills") {
        // agent fills
        // just save first three details of the agent visa (firstName, lastName, passportNum) -- without email
        this.setState({ passportNum: state.fieldVals.passportNum });
        visaService
          .updateAgentFirstData(state.visaId, state.fieldVals.passportNum, state.fieldVals.firstName, state.fieldVals.lastName)
          .then((res) => {
            if (res["success"]) {
              callback();
            }
          });
      } else {
        return callback();
      }
    } else {
      return callback();
    }
  };

  proccessOtherDepended = (question, lastVal, callback) => {
    if (!question.isOtherDepended) return callback();
    var litsDependedFields;
    var getQuestionsList = [...this.state.questionsListForDisplayment];
    if (question.dependedOn) {
      litsDependedFields = question.otherKeyDepended.split(",");
      if (question.dependedOn.toLowerCase() === lastVal.toLowerCase()) {
        getQuestionsList = this.addUnique(getQuestionsList, litsDependedFields);
      } else {
        getQuestionsList = getQuestionsList.filter((item) => !litsDependedFields.includes(item.key));
      }
    } else {
      litsDependedFields = question.dependedOnNot.split(",");
    }

    this.setState({ questionsListForDisplayment: getQuestionsList }, callback);
  };

  addUnique = (questionsList, keysToAdd) => {
    var originalList = this.state.questionsListForDisplaymentCopy;
    var adjust = 0;
    for (const key of keysToAdd) {
      if (questionsList.findIndex((item) => item.key === key) === -1) {
        questionsList.splice(this.state.questionIndexer + 1 + adjust, 0, originalList[originalList.findIndex((item) => item.key === key)]);
        adjust++;
      }
    }
    return questionsList;
  };

  handleKeyPress = (e) => {
    // Enter clicked
    if (e.charCode === 13) {
      var curQuestion = this.state.questionsListForDisplayment[this.state.questionIndexer];
      this.onContinue(
        curQuestion,
        this.state.fieldVals[curQuestion.key],
        curQuestion.confirmPass ? this.state.fieldVals[curQuestion.confirmPass] : ""
      );
    }
  };

  convertDateFieldVals = () => {
    var visaToEdit = this.props.visaToEdit;
    delete visaToEdit._id;
    delete visaToEdit.status;
    delete visaToEdit.dateCreated;

    var fieldVals = {};
    for (const key in visaToEdit) {
      var curItem = visaToEdit[key];
      if (typeof curItem === "string") {
        if (moment(curItem, moment.ISO_8601, true).isValid()) curItem = new Date(curItem);
        fieldVals[key] = curItem;
      } else {
        for (const innerKey in curItem) {
          var innerVal = curItem[innerKey];
          if (moment(innerVal, moment.ISO_8601, true).isValid()) innerVal = new Date(innerVal);
          fieldVals[innerKey] = innerVal;
        }
      }
    }
    return fieldVals;
  };

  removeDependedFieldsOnInit = (fields, fieldVals) => {
    for (const key in fieldVals) {
      if (fieldVals[key]) {
        var actualQuestion = fields.find((item) => item.key === key);
        if (!actualQuestion) continue; // from, to, visaType, etc..
        if (actualQuestion.isOtherDepended) {
          let litsDependedFields;
          if (actualQuestion.dependedOn) {
            litsDependedFields = actualQuestion.otherKeyDepended.split(",");
            if (actualQuestion.dependedOn.toLowerCase() !== fieldVals[key]) {
              fields = fields.filter((item) => !litsDependedFields.includes(item.key));
            }
          } else {
          }
        }
      }
    }
    return fields;
  };

  getQuestionIndexer = (fields, fieldVals) => {
    return fields.findIndex((item) => !fieldVals[item.key] || fieldVals[item.key].nameWithExtension);
  };
}

const mapStateToProps = (state) => {
  return {
    visaToEdit: state.loggedinReducer.visaToEdit,
    isLoggedIn: state.loggedinReducer.isLoggedIn
  };
};

export default connect(mapStateToProps)(OneByOneManager);
