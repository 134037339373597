import React, { useRef, useState, useEffect } from "react";
import visaService from "../../../Services/visaService";
import VisaSummary from "./visaSummary";
import VisaTypeCard from "./visaTypeCard";
import VisaSteps from "../../home/b2b/visaSteps";
import HomeFAQ from "../../home/b2b/homeFAQ";
import WhoServiceFor from "../../home/b2b/whoServiceFor";
import { Card, CardContent } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import ButtonUp from "../../widgets/ButtonUp";
import { connect } from "react-redux";
import config from "../../../config";

const ChooseVisaType = (props) => {
  const [from, setFrom] = useState(props.match.params.from);
  const [to, setTo] = useState(props.match.params.to);

  const [visaTypes, setVisaTypes] = useState();
  const [viewingVisaOpt, setViewingVisaOpt] = useState();
  const [focusedIndex, setFocusedIndex] = useState(0);

  const myRef = useRef();

  const queryParams = new URLSearchParams(props.location.search);
  const affiliateId = queryParams.get("affiliateId");

  useEffect(() => {
    if (props.isLoggedIn) {
      props.history.push("/agents/profile");
      return;
    }
    const getNewTo = props.match.params.to;
    const getNewFrom = props.match.params.from;
    setTo(getNewTo);
    setFrom(getNewFrom);

    visaService.getVisaTypes(getNewFrom, getNewTo).then((res) => {
      if (res["success"]) {
        const visaTypes = res["visaTypes"];
        setVisaTypes(visaTypes);
        setViewingVisaOpt(visaTypes[0]);
      }
    });
  }, [props.match.params.to, props.match.params.from]);

  const onFocusChanged = (newIndex) => {
    setFocusedIndex(newIndex);
    setViewingVisaOpt(visaTypes[newIndex]);
    myRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const visaTypeSelected = () => {
    let buildUrl = `/questionnaire/${from}/${to}/${viewingVisaOpt.visaType}`;
    if (affiliateId) buildUrl += `?affiliateId=${affiliateId}`;
    props.history.push(buildUrl);
  };

  let visaActualPrice, visaActualServicePrice;
  if (viewingVisaOpt) {
    visaActualPrice = config.isWebsiteB2C && viewingVisaOpt.visaPriceB2C ? viewingVisaOpt.visaPriceB2C : viewingVisaOpt.visaPrice;
    visaActualServicePrice =
      config.isWebsiteB2C && viewingVisaOpt.visaServiceB2C ? viewingVisaOpt.visaServiceB2C : viewingVisaOpt.visaService;
  }

  return (
    <div className="full-screen-questions-wrapper">
      <ButtonUp />
      <div className="questions-con relative">
        <div className="question-placement">
          <h1 className="primary-color center mt-30 mb-30">
            <b>הוצא ויזה עכשיו</b>
          </h1>
          <div className="container mt-20">
            <div className="visa-opts-con">
              <Card>
                <CardContent>
                  <h3 className="primary-color pr-30 no-padding-sm relative">
                    <div className="pass-icon-con">
                      <i className="material-icons color-success big-icon mt-10">style</i>
                    </div>
                    <div className="inline-block center-sm">יש צורך בויזה. נשמח לעמוד לשירותך ולעזור!</div>
                  </h3>
                  <hr />
                  <h5 className="lightgray-brand center-sm pr-30 no-padding-sm relative">
                    לחץ על הויזה המתאימה ולאחר מיכן על כפתור
                    <b> "קבל את הויזה שלך"</b>
                  </h5>

                  {visaTypes ? (
                    <div className="padding20">
                      {visaTypes.map((item, index) => {
                        return (
                          <VisaTypeCard
                            key={"dd" + index}
                            isFocused={focusedIndex === index}
                            onFocusChanged={() => {
                              onFocusChanged(index);
                            }}
                            visaType={visaTypes[index]}
                            visaActualPrice={config.isWebsiteB2C && item.visaPriceB2C ? item.visaPriceB2C : item.visaPrice}
                          />
                        );
                      })}
                    </div>
                  ) : null}
                </CardContent>
              </Card>
              <div className="mt-20">
                <Card>
                  <CardContent>
                    {viewingVisaOpt ? (
                      <div>
                        <h4 className="color-success">
                          מידע שחשוב לדעת לגבי ויזה מסוג - <b> {viewingVisaOpt.visaTitle}</b>
                        </h4>
                        <div>{ReactHtmlParser(viewingVisaOpt.freeTxt)}</div>
                      </div>
                    ) : null}
                  </CardContent>
                </Card>
              </div>
            </div>
            {viewingVisaOpt ? (
              <div ref={myRef} className="summary-con">
                <VisaSummary
                  viewingVisaOpt={viewingVisaOpt}
                  from={from}
                  to={to}
                  visaTypeSelected={visaTypeSelected}
                  visaActualPrice={visaActualPrice}
                  visaActualServicePrice={visaActualServicePrice}
                />
              </div>
            ) : null}
          </div>
          <div>
            <VisaSteps />
            <HomeFAQ />
            <WhoServiceFor />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.loggedinReducer.isLoggedIn
  };
};

export default connect(mapStateToProps)(ChooseVisaType);
