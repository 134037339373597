import axios from "axios";
import config from "../config";

const employeeServices = {
  // goes through protected route to get req.body.role for handling certain field errors
  getVisaFieldsAsEmployee: (from, to, visaType) => {
    return new Promise((resolve) => {
      let buildEndpoint;
      if (from && to && visaType) {
        buildEndpoint = `${config.getVisaFieldsAsEmployeeEndpoint}?from=${from}&to=${to}&visaType=${visaType}`;
      } else {
        buildEndpoint = config.getVisaFieldsAsEmployeeEndpoint;
      }
      axios.get(buildEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getOwnEmployeeManagedVisas: () => {
    return new Promise((resolve) => {
      axios.get(config.getOwnEmployeeVisasEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllFromToOptsAndFields: function () {
    return new Promise((resolve) => {
      axios.get(config.employeeAllFromToFieldsEndpoint).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAllAgencies: async () => {
    const serverRes = await axios.get(config.getAllAgenciesEndpoint);
    if (serverRes.data) return serverRes.data;
  },

  getAgenciesBySearchKey: (searchKey) => {
    return new Promise((resolve) => {
      axios.get(config.getAgenciesSearchKeyEmployeeEndpoint + "?searchKey=" + searchKey).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getVisasBySearch: (searchQuery, searchDates, fields, visasCategory) => {
    return new Promise((resolve) => {
      axios
        .post(config.employeeGetVisasBySearchEndpoint, {
          searchQuery,
          searchDates,
          fields,
          visasCategory
        })
        .then((serverRes) => {
          if (serverRes.data) return resolve(serverRes.data);
          return resolve({ err: "A problem occured with the request" });
        });
    });
  },

  getAgents: (agencyId) => {
    return new Promise((resolve) => {
      axios.get(config.getAgentsEndpoint + "?_id=" + agencyId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  },

  getAffiliatedVisaList: (agencyId) => {
    return new Promise((resolve) => {
      axios.get(config.getAffiliatedVisaListEndpoint + "?_id=" + agencyId).then((serverRes) => {
        if (serverRes.data) return resolve(serverRes.data);
        resolve({ err: "Problem with the request" });
      });
    });
  }
};

export default employeeServices;
