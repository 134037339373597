const domainUrl = window.location.host.replace("www.", "");
const supportedDomainList = ["visa-to-go.com", "visa2go.co.il", "myvisae.com"];

const activeDomain = supportedDomainList.find((domain) => domain.includes(domainUrl));

let apiUrlEndpoint, websiteUrlEndpoint, isWebsiteB2C;

if (activeDomain) {
  apiUrlEndpoint = "https://api.visa-to-go.com/";
  websiteUrlEndpoint = `https://${activeDomain}/`;

  if (activeDomain === "myvisae.com" || domainUrl.includes("/b2c") || localStorage.getItem("iswebsiteb2c")) {
    isWebsiteB2C = true;
  }
} else {
  apiUrlEndpoint = "http://localhost:7979/";
  websiteUrlEndpoint = "http://localhost:3000/";

  if (window.location.pathname.includes("/b2c") || localStorage.getItem("iswebsiteb2c")) {
    isWebsiteB2C = true;
  }
}

if (isWebsiteB2C) {
  // Change favicon
  const link = document.createElement("link");

  link.rel = "icon";
  link.type = "image/x-icon";
  link.href = "https://visa-downloadables.s3.eu-west-1.amazonaws.com/system-images/favicon-b2c.ico";

  // Remove existing favicon, if any
  const existingFavicon = document.querySelector('link[rel="icon"]');
  if (existingFavicon) {
    document.head.removeChild(existingFavicon);
  }

  document.head.appendChild(link);
}

var apiUrlProtectedEndpoint = apiUrlEndpoint + "loggedin/";
var webVersion = "1.0.0";

const websiteBaseUrl = "https://visa2go.co.il";

const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return "mobile";
  }
  return "desktop";
};

const currentDeviceType = deviceType();
const isMobile = currentDeviceType === "tablet" || currentDeviceType === "mobile";

const config = {
  isMobile,
  defaultLang: {
    langKey: "he",
    layoutDir: "rtl",
    langIcon: "he"
  },

  websiteUrlEndpoint,
  isWebsiteB2C,

  b2cLogoWhite: "https://visa-downloadables.s3.eu-west-1.amazonaws.com/system-images/b2c-homepage/logo-b2c-white.svg",

  allowedFilesFormat: [".jpeg", ".jpg", ".png", ".pdf"],
  imagesFormatToShow: [".jpeg", ".jpg", ".png"],

  amountLangsSupported: 2,
  amountOptionsAllowed: 150,

  supportNumber: "037705660",
  ourAddress: "שלום עליכם 5 תל אביב (בתוך פסאז)",
  supportEmail: "info@visa-to-go.com",

  webVersion,
  tranzillaUrl: "https://direct.tranzila.com/visa2go/iframenew.php?",
  executePaymentEndpoint: apiUrlEndpoint + "payments/execute-payment",
  paymentResultEndpoint: `${websiteBaseUrl}/payment-result`,
  allowedIframeUrls: ["https://visa2go.co.il", "https://www.visa2go.co.il", "https://visa-to-go.com", "https://www.visa-to-go.com"],
  getClientIpEndpoint: "https://jsonip.com",
  getLocationOfIpEndpoint: "https://ipapi.co/%ip%/json",

  reCaptchaKey: "6LeSnPoZAAAAAGhZr-cUFOL_l8U2MoQNb-pAEBi1",
  baseFlagUrl: "https://visa-downloadables.s3-eu-west-1.amazonaws.com/system-images/flags/",

  // Links/endpoints
  // unprotected
  allFromToOptsEndpoint: apiUrlEndpoint + "unprotected/from-to-opts",

  getVisaTypesEndpoint: apiUrlEndpoint + "unprotected/get-visa-types",
  getVisaFieldsEndpoint: apiUrlEndpoint + "unprotected/get-visa-fields",
  createNewVisaEndpoint: apiUrlEndpoint + "unprotected/new-visa",
  createNewGroupVisaEndpoint: apiUrlEndpoint + "unprotected/new-group-visa",
  updateVisaFieldsEndpoint: apiUrlEndpoint + "unprotected/update-visa-fields",
  updateVisaNameEndpoint: apiUrlEndpoint + "unprotected/update-visa-name",
  attachFileToVisaEndpoint: apiUrlEndpoint + "unprotected/attach-file-to-visa",

  loginAgentEndpoint: apiUrlEndpoint + "unprotected/agent-login",
  confEmailEndpoint: apiUrlEndpoint + "unprotected/conf-email",

  addParticipantToGroup: apiUrlEndpoint + "unprotected/add-participant-to-group",

  resendEmailConfEndpoint: apiUrlEndpoint + "unprotected/resend-email-conf",
  resetUserPassEndpoint: apiUrlEndpoint + "unprotected/reset-pass",
  changeUserPassEndpoint: apiUrlEndpoint + "unprotected/change-pass",
  contactUsMsgEndpoint: apiUrlEndpoint + "unprotected/contact-us-msg",
  newDubaiLeadEndpoint: apiUrlEndpoint + "unprotected/new-dubai-lead",
  retrieveVisaDetailsEndpoint: apiUrlEndpoint + "unprotected/retrieve-visa-details",
  finishUnpaidVisaEndpoint: apiUrlEndpoint + "unprotected/finish-unpaid-visa",
  finishVisaClientFillsEndpoint: apiUrlEndpoint + "unprotected/finish-visa-client-fills",

  // payment - tranzila.. not in use
  // finishVisaProcessEndpoint: apiUrlEndpoint + "payments/finish-visa-process",
  getPaymentIframeEndpoint: apiUrlEndpoint + "payments/get-iframe-payment-token",
  checkIsVisaPaidEndpoint: apiUrlEndpoint + "payments/check-is-visa-paid",

  // agents
  getAgentVisasEndpoint: apiUrlProtectedEndpoint + "agents/get-agent-visas",
  finishVisaProcessAgencyRelatedEndpoint: apiUrlProtectedEndpoint + "agents/finish-visa-process-agency-related",
  getAgentSpecificVisaEndpoint: apiUrlProtectedEndpoint + "agents/get-agent-specific-visa",
  getAllFromToAndPricesEndpoint: apiUrlProtectedEndpoint + "agents/from-to-opts-and-prices", // also field names
  initNewAgentVisaEndpoint: apiUrlProtectedEndpoint + "agents/init-new-agent-visa",
  initNewAgentVisaNoVoucherEndpoint: apiUrlProtectedEndpoint + "agents/init-new-agent-visa-no-voucher",
  updateAgentFirstDataEndpoint: apiUrlProtectedEndpoint + "agents/update-agent-first-data",
  getAgentGroups: apiUrlProtectedEndpoint + "agents/get-groups/",
  addGroup: apiUrlProtectedEndpoint + "agents/add-group",
  getAgentById: apiUrlProtectedEndpoint + "agents/get-agent-by-id/",
  deleteGroupById: apiUrlProtectedEndpoint + "agents/delete-group-by-id",
  updateGroupById: apiUrlProtectedEndpoint + "agents/update-group-by-id",
  getGroupById: apiUrlProtectedEndpoint + "agents/get-group-by-id/",
  getGroupParticipants: apiUrlProtectedEndpoint + "agents/get-group-participants/",
  updateParticipantById: apiUrlProtectedEndpoint + "agents/update-participant-by-id",
  agentDownloadUserFileEndpoint: apiUrlProtectedEndpoint + "agents/get-file/",
  agentDownloadUserFileFromSubfolderEndpoint: apiUrlProtectedEndpoint + "agents/get-file-from-subfolder/",
  getAgencyByIdEndpoint: apiUrlProtectedEndpoint + "agents/get-agency-by-id",

  // processor
  processorResendEmailConfEndpoint: apiUrlProtectedEndpoint + "processor/processor-resend-email-conf",
  processorGetAllVisasEndpoint: apiUrlProtectedEndpoint + "processor/get-all-visas",
  handleVisasChangesEndpoint: apiUrlProtectedEndpoint + "processor/handle-visas-changes",
  processorFieldChangesEndpoint: apiUrlProtectedEndpoint + "processor/field-changes",
  deleteFieldKeyEndpoint: apiUrlProtectedEndpoint + "processor/delete-field-key",
  createNewFromToOptEndpoint: apiUrlProtectedEndpoint + "processor/create-new-from-to",
  newFieldsFromToOptEndpoint: apiUrlProtectedEndpoint + "processor/new-fields-from-to",
  deleteFromToVisaTypeOptEndpoint: apiUrlProtectedEndpoint + "processor/delete-from-to-visa-Opt",
  handleAgencyChangesEndpoint: apiUrlProtectedEndpoint + "processor/handle-agency-changes",
  handleAgentChangesEndpoint: apiUrlProtectedEndpoint + "processor/handle-agent-changes",
  getAgencyReportEndpoint: apiUrlProtectedEndpoint + "processor/get-agency-report",
  getAgencyVouchersEndpoint: apiUrlProtectedEndpoint + "processor/get-agency-vouchers",
  attachFileForDownloadEndpoint: apiUrlProtectedEndpoint + "processor/attach-file-for-download",
  attachVisaFilesMultiEndpoint: apiUrlProtectedEndpoint + "processor/attach-visa-files-multi",
  getAllEmployeesEndpoint: apiUrlProtectedEndpoint + "processor/get-all-non-agent-employees",

  // employee endpoints go below
  getVisaFieldsAsEmployeeEndpoint: apiUrlProtectedEndpoint + "employee/get-fields-as-employee",
  getOwnEmployeeVisasEndpoint: apiUrlProtectedEndpoint + "employee/get-own-managed-visas",
  getAgenciesSearchKeyEmployeeEndpoint: apiUrlProtectedEndpoint + "employee/get-agencies-search-key",
  getAllAgenciesEndpoint: apiUrlProtectedEndpoint + "employee/get-all-agencies",
  employeeAllFromToFieldsEndpoint: apiUrlProtectedEndpoint + "employee/from-to-opts-and-fields", // also field names
  employeeGetVisasBySearchEndpoint: apiUrlProtectedEndpoint + "employee/get-visas-by-search",
  getAgentsEndpoint: apiUrlProtectedEndpoint + "employee/get-agents",
  getAffiliatedVisaListEndpoint: apiUrlProtectedEndpoint + "employee/get-affiliated-visa-list"
};

export default config;
